body.home {

	&{ background-color:$white; }
	.body-container{ background-color:$gray; }

	.site-contents{
	  &:after{
	    background-image: linear-gradient(180deg, rgba($gray,0) 0, $gray 100%);
	  }
	}
	address{ color:$white; }
	a{ color:$white; }
}
