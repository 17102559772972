
/* transition */
#globalNav,
#siteTitle,
#main-container,
#body-container,
#globalNav li a{
  transition: all 500ms ease(out-quart);
}

/* ===================
    ALL:
   =================== */

html,body{
  min-height:100%;
  height:100%;
}
body {
  margin: 0; padding: 10px;
  background-color:$light-gray;
  line-height: 1.4;
  font-family: $ja-font;
  font-size: 1em;
}
.body-container{
  @media screen and (max-width:765px){
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
  }
  margin: auto;
  height: 100%;
  border-radius: 10px;
  background-color:$white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:before{
    content: "";
    display: block; height: 0;
  }
}

.site-contents{
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  width: 100%; height: calc(100% - 3em);
  font-size:small;
  &:after{
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: calc( 33.333% + 1em);
    height: 4em;
    background-image: linear-gradient(180deg, rgba($white,0) 0, $white 100%);
  }
  @media screen and (max-width:765px){
    flex-direction: column;
    max-width: 100%;
    main{
      max-height: none;
      width: 90%;
      margin: auto;
    }
    &:after{ display: none; }
  }
}
.page-content{
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 100%;
  width: 33.333%;
  padding: 2em 1em 4em;
  line-height: 2;
}

h1,h2{ margin:0; font-size:small; }
h1{
  margin: 0;
  letter-spacing:0.5em;
  font-weight:normal;
  font-size:x-small;
  font-family: $en-font;
  color:$gray;
}
h2{
  margin:1em 0 0.5em;
  letter-spacing:0.125em;
  font-weight:normal;
  font-size:medium;
  color: $black;
}
h3{
  margin:2em 0 0.25em;
  letter-spacing:0.0625em;
  font-size:small;
  color: $black;
}
p[class$='-font-size']{
  line-height: 1.5;
}

.note{ line-height:1.5; color: $gray; }
.num{ letter-spacing:0; font-weight:bold; font-family: $en-font; }
em{
  font-style: normal;
  font-family: $en-font;
  font-size: 1rem;
}
main p a{
  text-decoration: underline;
}


/* =============================================================================
   Non-Semantic Helper Classes
   ========================================================================== */

.ir { display: block; border: 0; text-indent: -999em; overflow: hidden; background-color: transparent; background-repeat: no-repeat; text-align: left; direction: ltr; *line-height: 0; }
.ir br { display: none; }
.hidden { display: none !important; visibility: hidden; }
.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }
.invisible { visibility: hidden; }
.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { *zoom: 1; }
