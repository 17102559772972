$pure-white: #fff;
$true-black: #000;

$black: #3B3938;
$dark-gray: #5F5E5E;
$gray: #8E8E8F;
$light-gray: #c4c4c4;

$white: #FFFEF7;
$beige: #FFFDF1;
