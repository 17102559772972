.image-cover{
  object-fit: cover;
  font-family: 'object-fit: cover;'; //for IE,Edge poliyfill
}
.image-contain{
  object-fit: contain;
  font-family: 'object-fit: contain;'; //for IE,Edge poliyfill
}
img{
  overflow: hidden;
  vertical-align: bottom;
  &{ @extend .image-cover; }
  &.contain,
  .contain &{ @extend .image-contain; }
}
