.screen-reader-text,
.visually-hidden,
.skip-anchor:not(:focus) {
	clip: rect(1px,1px,1px,1px);
	overflow: hidden;
	position: absolute;
	padding: 0;
}
.skip-anchor {
	cursor: pointer;
	display: inline-block;
	text-decoration: underline;
}
