@charset "UTF-8";
.screen-reader-text,
.visually-hidden,
.skip-anchor:not(:focus) {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  position: absolute;
  padding: 0;
}

.skip-anchor {
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
}

* {
  box-sizing: border-box;
}

* {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

body {
  margin: 0;
  padding: 0;
}

a, p {
  word-wrap: break-word;
}

main {
  display: block;
}

figure {
  margin: 0;
}

address {
  font-style: normal;
}

img {
  max-width: 100%;
}

.no-bullet {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.align-middle {
  align-items: center;
}

.image-cover, img {
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.image-contain, img.contain, .contain img {
  object-fit: contain;
  font-family: "object-fit: contain;";
}

img {
  overflow: hidden;
  vertical-align: bottom;
}
@keyframes blink-once {
  0%, 49%, 100% {
    opacity: 1;
  }
  50%, 99% {
    opacity: 0;
  }
}
/* =============================================================================
   HTML5 Boilerplate CSS: h5bp.com/css
   ========================================================================== */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block;
}

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html, button, input, select, textarea {
  font-family: sans-serif;
  color: #3B3938;
}

body {
  margin: 0;
  font-size: 1em;
  line-height: 1.4;
}

::-moz-selection {
  background: #8E8E8F;
  color: #FFFEF7;
  text-shadow: none;
}

::selection {
  background: #8E8E8F;
  color: #FFFEF7;
  text-shadow: none;
}

a {
  text-decoration: none;
}

a:visited {
  color: #3B3938;
}

a:link {
  color: #8E8E8F;
}

a:hover {
  color: #8E8E8F;
}

a:focus {
  color: #8E8E8F;
}

a:hover, a:active {
  color: #8E8E8F;
}

ul, ol {
  margin: 1em 0;
  padding: 0 0 0 40px;
}

dd {
  margin: 0 0 0 40px;
}

nav ul, nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

label {
  cursor: pointer;
}

legend {
  border: 0;
  *margin-left: -7px;
  padding: 0;
  white-space: normal;
}

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

button, input {
  line-height: normal;
}

button, input[type=button], input[type=reset], input[type=submit] {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible;
}

button[disabled], input[disabled] {
  cursor: default;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  *width: 13px;
  *height: 13px;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

input:invalid, textarea:invalid {
  background-color: #f0dddd;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td {
  vertical-align: top;
}

.chromeframe {
  margin: 0.2em 0;
  background: #FFFEF7;
  color: #3B3938;
  padding: 0.2em 0;
}

/* transition */
#globalNav,
#siteTitle,
#main-container,
#body-container,
#globalNav li a {
  transition: all 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

/* ===================
    ALL:
   =================== */
html, body {
  min-height: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 10px;
  background-color: #c4c4c4;
  line-height: 1.4;
  font-family: "Optima nova LT W01", "Optima", "POMinIWA-Md", serif;
  font-size: 1em;
}

.body-container {
  margin: auto;
  height: 100%;
  border-radius: 10px;
  background-color: #FFFEF7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 765px) {
  .body-container {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
  }
}
.body-container:before {
  content: "";
  display: block;
  height: 0;
}

.site-contents {
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 3em);
  font-size: small;
}
.site-contents:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc( 33.333% + 1em);
  height: 4em;
  background-image: linear-gradient(180deg, rgba(255, 254, 247, 0) 0, #FFFEF7 100%);
}
@media screen and (max-width: 765px) {
  .site-contents {
    flex-direction: column;
    max-width: 100%;
  }
  .site-contents main {
    max-height: none;
    width: 90%;
    margin: auto;
  }
  .site-contents:after {
    display: none;
  }
}

.page-content {
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 100%;
  width: 33.333%;
  padding: 2em 1em 4em;
  line-height: 2;
}

h1, h2 {
  margin: 0;
  font-size: small;
}

h1 {
  margin: 0;
  letter-spacing: 0.5em;
  font-weight: normal;
  font-size: x-small;
  font-family: "Optima nova LT W01", "Optima", serif;
  color: #8E8E8F;
}

h2 {
  margin: 1em 0 0.5em;
  letter-spacing: 0.125em;
  font-weight: normal;
  font-size: medium;
  color: #3B3938;
}

h3 {
  margin: 2em 0 0.25em;
  letter-spacing: 0.0625em;
  font-size: small;
  color: #3B3938;
}

p[class$=-font-size] {
  line-height: 1.5;
}

.note {
  line-height: 1.5;
  color: #8E8E8F;
}

.num {
  letter-spacing: 0;
  font-weight: bold;
  font-family: "Optima nova LT W01", "Optima", serif;
}

em {
  font-style: normal;
  font-family: "Optima nova LT W01", "Optima", serif;
  font-size: 1rem;
}

main p a {
  text-decoration: underline;
}

/* =============================================================================
   Non-Semantic Helper Classes
   ========================================================================== */
.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
  *line-height: 0;
}

.ir br {
  display: none;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

/*
    ColorBox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

#cboxMiddleLeft, #cboxBottomLeft {
  clear: left;
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow: auto;
}

#cboxTitle {
  margin: 0;
}

#cboxLoadingOverlay, #cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
  cursor: pointer;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
}

/*
    User Style:
    Change the following styles to modify the appearance of ColorBox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay {
  background: #000;
}

#cboxContent {
  margin-top: 20px;
}

.cboxIframe {
  background: #fff;
}

#cboxError {
  padding: 50px;
  border: 1px solid #ccc;
}

#cboxLoadedContent {
  border: 5px solid #000;
  background: #fff;
}

#cboxTitle {
  position: absolute;
  top: -20px;
  left: 0;
  color: #ccc;
}

#cboxCurrent {
  position: absolute;
  top: -20px;
  right: 0px;
  color: #ccc;
}

#cboxSlideshow {
  position: absolute;
  top: -20px;
  right: 90px;
  color: #fff;
}

#cboxPrevious:hover {
  background-position: bottom left;
}

#cboxNext:hover {
  background-position: bottom right;
}

#cboxLoadingOverlay {
  background: #000;
}

#cboxClose:hover {
  background-position: bottom center;
}

/*#colorbox, #cboxOverlay, #cboxWrapper{overflow:auto;}*/
#cboxTitle {
  opacity: 0.9;
}

#cboxTitle:hover {
  opacity: 1;
}

#cboxTitle {
  top: auto;
  bottom: 0;
}

#cboxTitleInner {
  padding: 20px;
  background: url(../images/bg_transparent_black40.png);
}

#cboxTitle h1 {
  margin-bottom: 1em;
  font-size: small;
  color: #fff;
}

#cboxTitle p {
  margin: 0;
  font-size: small;
  color: #fff;
  line-height: 1.5;
}

#cboxCurrent {
  font-size: x-small;
}

.site-footer {
  margin: 1em 1.5em;
  font-size: x-small;
  color: #3B3938;
}
.site-footer address {
  font-style: normal;
}

.header-nav {
  display: flex;
  justify-content: center;
  width: 66.666%;
  text-transform: uppercase;
  font-family: "Optima nova LT W01", "Optima", serif, serif;
}
@media screen and (max-width: 765px) {
  .header-nav {
    flex-direction: column;
    width: calc(90% + 26px);
    min-height: 262px;
    margin: auto;
    padding-top: 1em;
  }
}
.header-nav li.news {
  letter-spacing: 1.35em;
}
.header-nav li.works {
  letter-spacing: 0.82em;
  text-indent: -0.025em;
}
.header-nav li.about {
  letter-spacing: 0.85em;
}
.header-nav li.contact {
  letter-spacing: 0.37em;
}
.header-nav li a {
  display: block;
  padding: 15px;
  margin: 15px 30px;
  line-height: 15px;
  font-size: 1rem;
}
@media screen and (max-width: 765px) {
  .header-nav li a {
    margin: 2px 12px;
  }
}
body.home .header-nav li a {
  color: #FFFDF1;
}
.header-nav li a:hover {
  color: #8E8E8F;
}
.header-nav img {
  min-width: 198px;
  height: 19px;
}
.header-nav__title a {
  position: relative;
  display: block;
  margin: 0 -20px 0 0;
  padding: 20px;
  padding-right: 35px;
}
@media screen and (max-width: 765px) {
  .header-nav__title a {
    margin-right: 0;
    margin-top: 0;
  }
}
@media screen and (min-width: 766px) {
  body#home .header-nav__title a {
    margin-top: 98px;
  }
  body#news .header-nav__title a {
    margin-top: 8px;
  }
  body#about .header-nav__title a {
    margin-top: 128px;
  }
  body#contact .header-nav__title a {
    margin-top: 188px;
  }
  .header-nav__title a:after {
    position: absolute;
    top: calc(50% - 6px);
    right: 6px;
    content: "";
    transform: rotate(45deg);
    transform-origin: center;
    width: 12px;
    height: 12px;
    border-top: 1px solid;
    border-right: 1px solid;
    color: #8E8E8F;
  }
}

@media screen and (min-width: 766px) {
  body.works .header-nav {
    width: 980px;
    height: 50px;
    margin-top: 2em;
    margin-bottom: 1em;
    flex: 0 0 auto;
    justify-content: flex-start;
  }
  body.works .header-nav__content {
    position: relative;
    z-index: 256;
    margin-top: -7px;
  }
  body.works .header-nav__content.open ul {
    padding-top: 15px;
  }
  body.works .header-nav__content.open li {
    display: block;
  }
  body.works .header-nav__content.open a {
    background-color: #FFFEF7;
    margin-top: 0;
    margin-bottom: 0;
  }
  body.works .header-nav__content.open a:hover {
    background-color: #fff;
  }
  body.works .header-nav li {
    display: none;
  }
  body.works .header-nav li.works {
    display: block;
  }
  body.works .header-nav #menu {
    display: block;
    position: absolute;
  }
}
#menu {
  display: none;
  top: 20px;
  right: -33px;
  padding: 1em;
  padding-right: 22px;
  font-family: "Optima nova LT W01 Demi", "Optima", serif;
  font-size: 11px;
}
#menu:before, #menu:after {
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: absolute;
  content: "";
  display: block;
  width: 13px;
  height: 2px;
  background-color: #8E8E8F;
  transform-origin: center;
}
#menu:before {
  top: calc(50% - 4px);
  right: 0;
}
.open #menu:before {
  top: calc(50% - 2px);
  transform: rotate(-45deg);
}
#menu:after {
  bottom: calc(50% - 2px);
  right: 0;
}
.open #menu:after {
  bottom: 50%;
  transform: rotate(45deg);
}

/* contact */
table {
  max-width: 100%;
}
table th,
table td {
  border: none;
  padding: 0 0 3px;
}
table th {
  padding-right: 1em;
  text-align: left;
  font-weight: normal;
  white-space: nowrap;
}
table th.ex {
  letter-spacing: 1em;
}

form select,
form textarea,
form input[type=text],
form input[type=number],
form input[type=email] {
  padding: 0.75em 3em 0.75em 1em;
  background-color: #fff;
  border: 1px solid #c4c4c4;
}
form select:focus,
form textarea:focus,
form input[type=text]:focus,
form input[type=number]:focus,
form input[type=email]:focus {
  color: #3B3938;
}
form textarea,
form input[type=text],
form input[type=number],
form input[type=email] {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  border: 1px solid #c4c4c4;
}
form textarea:focus,
form input[type=text]:focus,
form input[type=number]:focus,
form input[type=email]:focus {
  color: #3B3938;
}
form table ~ p {
  text-align: center;
}
form input[type=submit] {
  appearance: none;
  padding: 0.75em 1.5em;
  background-color: #8E8E8F;
  border: none;
  border-radius: 0.25em;
  text-align: center;
  font-weight: bold;
  color: #FFFDF1;
}
form input[type=submit]:hover {
  background-color: #5F5E5E;
}
form input[name=郵便番号] {
  width: 10em;
}
form select {
  position: relative;
  appearance: none;
  padding: 0.75em 3em 0.75em 1em;
  border-radius: 0.25em;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23626E6C'><polygon points='0,0 100,0 50,50'/></svg>");
  background-size: 12px;
  background-position: calc(100% - 12px) calc(50% + 3px);
  background-repeat: no-repeat;
}
form select:focus {
  border-color: #FFFEF7;
  border-radius: 0;
}

@media screen and (max-width: 765px) {
  table th,
table td {
    display: block;
  }
}
.thankyou-text {
  display: block;
  margin: 60px 0 15px;
}

body.home {
  background-color: #FFFEF7;
}
body.home .body-container {
  background-color: #8E8E8F;
}
body.home .site-contents:after {
  background-image: linear-gradient(180deg, rgba(142, 142, 143, 0) 0, #8E8E8F 100%);
}
body.home address {
  color: #FFFEF7;
}
body.home a {
  color: #FFFEF7;
}

/* #works */
body.works {
  /* end works */
}
body.works .site-contents {
  flex-direction: column;
  float: none;
  width: 1000px;
  margin: 0 auto;
  position: relative;
  /* single */
}
body.works .site-contents:after {
  width: 100%;
}
body.works .site-contents main {
  width: 100%;
}
body.works .site-contents #archives ul {
  margin: 0 5px;
  padding: 0;
  list-style-type: none;
}
body.works .site-contents #archives h2 {
  margin: 3px 0 0;
  line-height: 1.5;
  letter-spacing: 0.5em;
  font-size: x-small;
  font-family: "Optima nova LT W01", "Optima", serif;
}
body.works .site-contents #archives img {
  width: 100%;
  height: auto;
}
@media screen and (min-width: 766px) {
  body.works .site-contents h1 {
    margin-left: 20px;
  }
  body.works .site-contents #archives li {
    float: left;
    width: 33.333%;
    height: 270px;
    padding: 0 15px;
  }
  body.works .site-contents #archives p.thumb {
    overflow: hidden;
    width: 300px;
    height: 200px;
    margin: 0;
  }
}
@media screen and (max-width: 765px) {
  body.works .site-contents h1 {
    margin-left: 8px;
  }
  body.works .site-contents #archives li {
    margin-bottom: 4em;
  }
}
body.works .site-contents .post h1 {
  margin: 1.5em 0 1em;
}
body.works .site-contents .post p {
  text-align: justify;
}
body.works .site-contents #imagefield_large img {
  width: 635px;
  height: auto;
}
body.works .site-contents .post div.right {
  overflow: hidden;
  float: right;
  width: 300px;
  padding-right: 20px;
  margin-bottom: 40px;
}
body.works .site-contents #subImages p.photo {
  width: 300px;
  height: auto;
  margin: 0 0 5px;
}
body.works .site-contents #subImages p.photo img {
  width: 300px;
  height: auto;
}
body.works .site-contents #numImages {
  overflow: hidden;
  padding: 1.5em 0 1em;
  font-size: 16px;
}
body.works .site-contents #numImages p.photo {
  float: left;
  margin: 0 1px 0 0;
}
body.works .site-contents #numImages p.photo a {
  display: block;
  width: 25px;
  text-align: center;
  text-decoration: none;
  font-size: small;
  font-family: "Optima nova LT W01", "Optima", serif;
}
@media screen and (min-width: 766px) {
  body.works .site-contents .post div.left {
    overflow: hidden;
    float: left;
    width: 630px;
    padding-left: 20px;
    margin-bottom: 40px;
  }
  body.works .site-contents #subImages,
body.works .site-contents #imagefield_large {
    overflow: hidden;
    height: 405px;
    margin-top: 0;
  }
}
@media screen and (max-width: 765px) {
  body.works .site-contents #subImages {
    margin-top: 4em;
  }
}
body.works .site-contents .spec {
  clear: both;
}