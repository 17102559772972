.header-nav{
	display: flex;
	justify-content: center;
	width: 66.666%;
	text-transform: uppercase;
	font-family: $en-font, serif;
	@media screen and (max-width:765px){
    flex-direction: column;
		width: calc(90% + 26px);
		min-height: 262px;
		margin: auto;
		padding-top: 1em;
  }
	li{
		&.news   { letter-spacing: (1350em / 1000); }
		&.works  { letter-spacing: ( 820em / 1000); text-indent: -0.025em; }
		&.about  { letter-spacing: ( 850em / 1000); }
		&.contact{ letter-spacing: ( 370em / 1000); }
	}
	li a{
		display:block;
		padding:15px;
		margin:15px 30px;
		line-height: 15px;
		font-size: 1rem;
		@media screen and (max-width:765px){
			margin:2px 12px;
	  }
		body.home &{ color: $beige; }
		&:hover { color: $gray; }
	}
	img{
		min-width: 198px;
		height: 19px;
	}
	&__title a{
		position: relative;
		display:block; margin:0 -20px 0 0;
		padding: 20px;
		padding-right:35px;
		@media screen and (max-width:765px){
	    margin-right: 0;
			margin-top: 0;
	  }
		@media screen and (min-width:766px){
			body#home	   &{ margin-top:98px;  }
			body#news	   &{ margin-top: 8px;  }
			body#about	 &{ margin-top:128px; }
			body#contact &{ margin-top:188px; }
			&:after{
				position: absolute;
				top: calc(50% - 6px); right: 6px;
				content: '';
				transform: rotate(45deg);
				transform-origin: center;
				width: 12px; height: 12px;
				border-top: 1px solid;
				border-right: 1px solid;
				color: $gray;
			}
	  }
	}
}

@media screen and (min-width:766px){
	body.works .header-nav{
		width: 980px; height: 50px;
		margin-top: 2em; margin-bottom: 1em;
		flex: 0 0 auto;
		justify-content: flex-start;
		&__content{
			position: relative; z-index: 256;
			margin-top: -7px;
			&.open{
				ul{ padding-top:15px; }
				li{ display: block; }
				a{ background-color:$white; margin-top:0; margin-bottom:0; }
				a:hover{ background-color:$pure-white; }
			}
		}
		li{
			display: none;
			&.works  { display: block; }
		}
		#menu{
			display: block;
			position: absolute;
		}
	}}

#menu{
	display: none;
	top: 20px;
	right: -33px;
	padding: 1em;
	padding-right: 22px;
	font-family: $en-font-demi;
	font-size: 11px;
	&:before, &:after{
		transition: all 0.25s ease(out-quart);
		position: absolute;
		content: '';
		display: block;
		width: 13px;
		height: 2px;
		background-color: $gray;
		transform-origin: center;
	}
	&:before{
		top: calc(50% - 4px);
		right: 0;
		.open &{
			top: calc(50% - 2px);
			transform: rotate(-45deg);
		}
	}
	&:after{
		bottom: calc(50% - 2px);
		right: 0;
		.open &{
			bottom: 50%;
			transform: rotate(45deg);
		}
	}
}
