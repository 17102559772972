/* #works */ body.works{ //

.site-contents{
  flex-direction: column;
  float:none; width:1000px; margin:0 auto; position:relative;
  &:after{
    width: 100%;
  }
  main{
    width: 100%;
  }
  #archives{
    ul{
      margin:0 5px; padding: 0; list-style-type:none;
    }
    h2{
      margin:3px 0 0;
      line-height:1.5;
      letter-spacing:0.5em;
      font-size:x-small;
      font-family: $en-font;
    }
    img{ width:100%; height:auto; }
  }
  @media screen and (min-width:766px){
    h1{ margin-left:20px; }
    #archives{
      li{ float:left; width:33.333%; height:270px; padding:0 15px; }
      p.thumb{ overflow:hidden; width:300px; height:200px; margin:0; }
    }
  }
  @media screen and (max-width:765px){
    h1{ margin-left:8px; }
    #archives{
      li{
        margin-bottom: 4em;
      }
    }
  }
  /* single */
  .post h1{ margin:1.5em 0 1em; }
  .post p{ text-align:justify; }
  #imagefield_large img{ width:635px; height:auto; }
  .post div.right{ overflow:hidden; float:right; width:300px; padding-right:20px; margin-bottom:40px; }
  #subImages p.photo{ width:300px; height:auto; margin:0 0 5px; }
  #subImages p.photo img{ width:300px; height:auto; }
  #numImages{ overflow:hidden; padding:1.5em 0 1em; font-size:16px; }
  #numImages p.photo{ float:left; margin:0 1px 0 0; }
  #numImages p.photo a{
    display:block;
    width:25px;
    text-align:center;
    text-decoration: none;
    font-size:small;
    font-family: $en-font;
  }
  @media screen and (min-width:766px){
    .post div.left{ overflow:hidden; float:left; width:630px; padding-left:20px; margin-bottom:40px; }
    #subImages,
    #imagefield_large{ overflow:hidden; height:405px;
   	margin-top:0; }
  }
  @media screen and (max-width:765px){
    #subImages{
   	  margin-top:4em;
    }
  }
  .spec{ clear:both; }
}

/* end works */ } //
