/* contact */
table{
  max-width: 100%;
  th,
  td{ border:none; padding:0 0 3px; }
  th{
    padding-right:1em; text-align:left; font-weight:normal;
    white-space: nowrap;
  }
  th.ex{ letter-spacing:1em; }
}
form{
  select,
  textarea,
  input[type='text'],
  input[type='number'],
  input[type='email']{
    padding: 0.75em 3em 0.75em 1em;
    background-color:$pure-white;
    border:1px solid $light-gray;
    &:focus{ color:$black; }
  }
  textarea,
  input[type='text'],
  input[type='number'],
  input[type='email']{
    display: inline-block;
    width: 100%;
    max-width: 100%;
    border:1px solid $light-gray;
    &:focus{ color:$black; }
  }
  table ~ p{
    text-align: center;
  }
  input[type="submit"]{
    appearance: none;
    padding: 0.75em 1.5em;
    background-color:$gray;
    border:none;
    border-radius: 0.25em;
    text-align:center;
    font-weight:bold;
    color:$beige;
    &:hover{ background-color:$dark-gray; }
  }
  input[name="郵便番号"]{
    width: 10em;
  }

  select{
    position: relative;
    appearance: none;
    padding: 0.75em 3em 0.75em 1em;
    border-radius: 0.25em;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23626E6C'><polygon points='0,0 100,0 50,50'/></svg>");
    background-size: 12px;
    background-position: calc(100% - 12px) calc(50% + 3px);
    background-repeat: no-repeat;
    &:focus{
      border-color: $white;
      border-radius: 0;
    }
  }
}

@media screen and (max-width:765px){
  table{
    th,
    td{ display: block; }
  }
}

.thankyou-text{
  display:block; margin:60px 0 15px;
}
